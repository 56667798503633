const MobileHeader = () => (
  <header className="mobile-header-2">
    <div className="container">
      {/* <!-- menu icon --> */}
      <div className="menu-icon d-inline-flex mr-4">
        <button>
          <span></span>
        </button>
      </div>
    </div>
  </header>
);

export default MobileHeader;