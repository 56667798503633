import YinYangIcon from '../styles/images/yin-yang.png';
import IpfsIcon from '../styles/images/ipfs-powered.svg';
import EffIcon from '../styles/images/tech-icons/eff-2022-membership-badge.png';

const Footer = () => (
  <section id="footer">
    <a className="mx-1" href="https://en.wikipedia.org/wiki/Yin_and_yang" title="Yin/Yang" target="_blank" rel='noopener noreferrer'>
      <img src={YinYangIcon} alt="Yin/Yang"/>
    </a>
    <a className="footer-ipfs mx-1" href="https://ipfs.io/" title="Powered by IPFS" target="_blank" rel="noopener noreferrer">
      <img src={IpfsIcon} alt="Powered by IPFS"/>
    </a>
    <a className="mx-1" href="https://www.eff.org/" title="EFF" target="_blank" rel="noopener noreferrer">
      <img src={EffIcon} alt=""/>
    </a>
  </section>
);

export default Footer;